<template>
    <div v-if="!$user()" class="alert alert-info small mt-3">
        <div class="row">
            <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                <nuxt-link to="/@example">
                    <img src="/img/example-card.png" alt="Screenshot of an example card" class="rounded shadow mw-100">
                </nuxt-link>
            </div>
            <div class="col">
                <p>
                    <T>profile.banner</T>
                </p>
                <p>
                    <nuxt-link to="/@example" class="btn btn-outline-primary btn-sm mx-1 mb-1">
                        <Icon v="id-card" />
                        <T>profile.example</T>
                    </nuxt-link>
                    <nuxt-link :to="{ name: 'user' }" class="btn btn-primary btn-sm mx-1 mb-1">
                        <Icon v="id-card" />
                        <T>profile.bannerButton</T>
                    </nuxt-link>
                </p>
            </div>
        </div>
    </div>
</template>
